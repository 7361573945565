<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-12">
            <div class="page-title">
              <h1>
                Privacy Policy
              </h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="terms-of-service pt-100 pb-100">
      <div class="container">
        <div class="text-wrapper">
          <div class="row">
            <div class="col-12">
              <h4 class="mt-20 mb-1">Who we are:</h4>
              <p>Our website address is: <a href="https://healthtravelsecure.com" target="_blank">https://healthtravelsecure.com.</a></p>

              <br>

              <h4 class="mt-20 mb-1">What personal data we collect and why we collect it:</h4>

              <br>
              <h4 class="mt-20 mb-1">Comments</h4>

              <p>
                When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.
                An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Media</h4>
              <p>
                If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                Contact forms
              </p>

              <br>

              <p>
                We retain the information that you submit in our contact forms and reserve the right to share it with service providers as needed to complete the tasks related to your contact request.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Cookies</h4>
              <p>
                If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
              </p>
              <p>
                If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
                When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
              </p>
              <p>
                If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Embedded content from other websites</h4>
              <p>
                Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.<br>
                These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Analytics</h4>
              <p>
                We have both Google and Facebook tracking pixels on our sites, this records things like how often you visit, what pages you visit and how long you spend on each page.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Who we share your data with</h4>
              <p>
                Your privacy is important and we use industry-standard procedures to ensure that we keep your data as secure as possible. We reserve to right to share your data with any service providers who need it to complete their related tasks, we try to provide it in hashed format when possible and only selectively share the information necessary to complete the task assigned them.  We may also share your data in the event that our company is acquired.
              </p>

              <br>

              <h4 class="mt-20 mb-1">How long we retain your data</h4>
              <p>
                If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.<br>
                For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
              </p>

              <br>

              <h4 class="mt-20 mb-1">What rights you have over your data</h4>
              <p>
                If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
              </p>

              <br>

              <h4 class="mt-20 mb-1">Where we send your data</h4>
              <p>
                Visitor comments may be checked through an automated spam detection service.
              </p>

              <br>

              <h4 class="mt-20 mb-1">How we protect your data</h4>
              <p>We use SSL secure socket layers to provide an encrypted connection for your data</p>

              <br>

              <h4 class="mt-20 mb-1">What third parties we receive data from</h4>
              <p>Google and Facebook provide retargeting data to us</p>

              <br>

              <h4 class="mt-20 mb-1">What automated decision making and/or profiling we do with user data</h4>
              <p>We currently only sort based on the procedure you’re interested in.</p>

              <br>

              <h4 class="mt-20 mb-1">PRIVACY</h4>
              <p>
                Parties shall treat as confidential all information made available to them through the Platform or else, and shall not disclose such confidential information without the written consent of the owner of that information. However, HTS will use and is authorized herein by you to use the information of your payment card and the information contained herein, (i) to provide you with the Services; and (ii) to share it with the Third Parties that will provide you with Third Party Services.<br>
                To facilitate your use of the Platform and determine the eligibility of your card, we will share your payment card details and transaction details with other third parties such as those assisting us with transaction processing. The foregoing restriction on use and disclosure shall not apply to confidential information which, at the time of disclosure or its becoming known to the recipient, the recipient can show (i) is public knowledge; or (ii) came lawfully into the recipient&#39;s possession otherwise than directly or indirectly from the owner without restriction on its subsequent disclosure or use by the recipient. For the Platform to function correctly, we may collect, use, and share precisely your geographical location data.<br>
                By agreeing to these Terms, you are agreeing to and you consent to (i) the processing of your personal information as explained above; and (ii) the collection of information by the Platform as explained above. The parties of these Terms have declared that they have provided personal information voluntarily and with the informed consent needed for the correct execution of these services in relation, to compliance with the purposes
                established by the National Constitution, Law 1581 of 2012, the Complementary Decree 1377 of 2013 and any other applicable Colombian legislation, and especially, to comprehensively develop the object of these Terms. The rights of the Parties, as the owners of the information, are the ones ruled in the National Constitution and in the law, the parties especially have the right to know, update, correct and delete any of their personal information, also they have the right to revoke the consent given for the treatment of their personal information with the legal exceptions. The rights of the parties as owners of their personal data can be
                exercised throughout the channels of communication established between the parties. The parties are obliged to guarantee the confidentiality, liberty, security, veracity, transparency, access and restricted circulation of the provided personal data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    export default {
        name: "privacy-policy",
        created() {
            window.scrollTo(0, 0);
        }
    }
</script>

<style scoped>
  .breadcrumb-bg {
    background-image: url("../assets/img/services-banner.png");
    background-position: top;
    position: relative;
  }
  .breadcrumb-bg::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.72);
  }
  .breadcrumb-bg .container {
    position: relative;
    z-index: 2;
  }
  .text-wrapper {
    background-color: #fff;
    padding: 50px 80px;
    box-shadow: 0 0 12px -3px #e8e8e8;
  }
  .text-wrapper h4 {
    width: 100%;
    color: #223645;
  }
  .text-wrapper p {
    font-size: 18px;
  }
  .text-wrapper a {
    color: #18a346;
  }
  .text-wrapper a:hover {
    color: #8fb569;
  }
  @media (max-width: 768px) {
    .text-wrapper {
      padding: 50px 30px;
    }
  }
</style>
